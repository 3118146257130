<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="权限名称" prop="name" :rules="rules.required">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="上级" prop="parent" :rules="rules.parent">
					<el-cascader class="width-100p" :options="options" :props="{ checkStrictly: true, value:'id', label:'title'}"
					clearable v-model="form.parent"></el-cascader>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">{{saveBtn}}</el-button>
					<el-button @click="$router.go(-1)">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules: {
					...rules,
					parent: {
						validator: (rule, value, callback) => {
							let err = null;
							console.log(value,Number(this.form.id))
							if (value.indexOf(Number(this.form.id)) >= 0) {
								err = new Error('不能将此权限设置为本权限的下级');
							}
							err ? callback(err) : callback();
						}
					},
				}, // 表单校验
				title: this.$route.params.id ? "编辑权限" : "新增权限",
				saveBtn: this.$route.params.id ? "确认保存" : "确认创建",
				form: {
					id: this.$route.params.id,
					name: "",
					type: 2,
					parent: []
				},
				options: [] // 父权限
			};
		},
		mounted() {
			// 父权限
			this.$api.system.getPowers().then(res => {
				this.options = res.data;
			})
			// 初始数据
			if (this.$route.params.id) {
				this.$api.system.getPower({
					id: this.$route.params.id
				}).then(res => {
					this.form.name = res.data.title;
					this.form.parent = res.data.parent_path || [];
				});
			}
		},
		methods: {
			onSubmit() {
				// 获取parent_id
				let parent = this.form.parent[this.form.parent.length - 1] || 0;
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.system.savePower({
								id: this.form.id,
								title: this.form.name,
								parent_id: parent,
								parent_path: this.form.parent,
							})
							.then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
